<template>
  <el-form
    label-position="top"
    ref="formRef"
    :model="form"
    :rules="rules"
    class="px-10 mt-6 mb-10"
  >
    <el-form-item label="展覽名稱" prop="title">
      <El-input v-model="form.title" placeholder="請輸入展覽名稱">
        <template #suffix></template>
      </El-input>
    </el-form-item>
    <el-form-item>
      <el-button
        class="mt-12 mx-auto block"
        type="primary"
        @click.prevent="sumbitForm"
        >建立展覽</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
import { reactive, ref } from '@vue/reactivity';
import { ElInput, ElButton, ElForm, ElFormItem } from 'element-plus';
export default {
  emits: ['create'],
  components: { ElInput, ElButton, ElForm, ElFormItem },
  setup(props, { emit }) {
    // , create: 1
    const form = reactive({ title: '' });
    const formRef = ref(null);
    const rules = {
      title: [
        {
          required: true,
          message: '請填寫欄位',
          trigger: 'blur',
        },
      ],
    };

    const sumbitForm = () => {
      formRef.value.validate((valid) => {
        if (valid) {
          submit();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    };

    const submit = (params) => {
      emit('create', { ...form });
    };
    return { form, sumbitForm, rules, formRef };
  },
};
</script>
