<template>
  <div class="">
    <App-Necker :title="'策展管理'"></App-Necker>
    <div class="content flex flex-col" v-loading.lock="loading">
      <div class="flex items-end justify-between">
        <Filter v-if="!$state.isMobile" :inline="true" />
        <div v-else>
          <El-Button class="" @click="show = true">搜尋條件</El-Button>
          <Drawer :size="300" v-model:show="show">
            <div class="px-6">
              <Filter :inline="false" />
            </div>
          </Drawer>
        </div>
        <El-Button
          class="xl"
          type="primary"
          @click.prevent="
            dialog.toggle({
              component: 'CreateForm',
              attrs: { title: '新增展覽' },
            })
          "
          >新增展覽</El-Button
        >
      </div>
      <div class="mt-12 flex flex-col w-full border-gray-500 border-b">
        <div
          class="flex py-4 border-gray-500 border-t"
          v-for="row in rows"
          :key="row.id"
        >
          <div class="w-6/12">
            <h4 class="font-bold text-lg truncate" v-highlight="query?.keyword">
              {{ row.title }}
            </h4>
            <p class="text-gray-500 truncate">策展人 : {{ row.author }}</p>
          </div>
          <div class="ml-auto flex items-center lg:text-base text-xs">
            <div v-if="row.status === 'Review'">審核中</div>
            <div v-else-if="row.status === 'Published'">已通過審核</div>
            <div v-else-if="row.status === 'Invalid'">審核失敗</div>

            <El-Button
              v-if="
                row.status === 'New' ||
                row.status === 'Invalid' ||
                row.status === 'Published' ||
                row.status === 'Review'
              "
              class="lg:px-16 ml-4 p-1 lg:text-sm text-xs"
              @click.prevent="router.push({ path: `/editor/${row.id}/space` })"
              >編輯</El-Button
            >
            <El-Button
              v-if="row.status === 'New' || row.status === 'Invalid'"
              class="lg:px-16 ml-4 p-1 lg:text-sm text-xs"
              @click.prevent="del({ id: row.id })"
              >刪除</El-Button
            >
          </div>
        </div>
      </div>
      <Pagination />
    </div>
    <El-Dialog
      title="新增展覽"
      v-model="dialog.state.show"
      width="50%"
      @close="dialog.toggle()"
    >
      <component :is="dialog.state.component" @create="onCreate"></component>
    </El-Dialog>
  </div>
</template>

<script>
import { provide, ref, inject } from 'vue';
import { useRouter, useRoute } from 'vue-router';

import Drawer from '@/components/Drawer.vue';
import useDialog from '@/components/useDialog';
import Pagination from '@/pages/index/components/list/Pagination.vue';
import Filter from '@/pages/index/components/list/Filter.vue';
import useList from '@/pages/index/components/list/useList';

import useFilter from '@/pages/index/components/list/useFilter';
import { ElDialog, ElButton } from 'element-plus';
import ExhibService from '@/services/Exhib';

import CreateForm from './Create.vue';

export default {
  components: {
    Filter,
    ElDialog,
    ElButton,
    Pagination,

    CreateForm,
    Drawer,
  },

  setup() {
    const router = useRouter();
    const show = ref(false);

    // 傳入 有哪些條件 和service api

    const { query, opts } = useFilter({
      fields: {
        status: '',
        keyword: '',
        create: 1,
        // start_ts: '',
        // end_ts: '',
        // start_ts: dayjs().format('YYYY-MM-DD'),
        // end_ts: dayjs().add(1, 'month').format('YYYY-MM-DD'),
      },
    });

    const { data, loading, update, pagination, create, del } = useList({
      query: query, //透過filters 產生
      service: ExhibService,
      url: 'mine',
    });
    update();
    provide('filter', {
      query,
      opts,
    });

    provide('list', {
      loading,
      update,
      pagination,
    });

    // fetcher();
    const dialog = useDialog();
    const onCreate = (params) => {
      dialog.toggle();
      create(params);
    };

    return {
      onCreate,
      show,
      router,
      dialog,
      del,
      rows: data,
      loading,
      query,
    };
  },
};
</script>
